<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CompanyManagerPopup v-if="pop.isShowCManager" :company="mod.info.idx_company" v-model:manager.sync="mod.info.company_manager" v-model="mod.info.idx_bizcard" @close="pop.hideCManager"></CompanyManagerPopup>
        <SelectClassPopup v-if="pop.isShowClass" v-model:company.sync="mod.info.idx_company" v-model:classname.sync="mod.info.classname" v-model="mod.info.out_prc_idx_class" @close="pop.hideClassList"></SelectClassPopup>

        <CarrotTitle title="고객사일지 수정"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><span class="txt-red">*</span> 일시</th>
                                <td>{{ mod.info.vdate }}</td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 외근목적</th>
                                <td>{{ mod.info.rtype }}</td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 고객사</th>
                                <td>{{ mod.info.company_kname }}</td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 업체담당자</th>
                                <td>
                                    <input type="text" v-model="mod.info.company_manager" class="w-400px float-left mr-5" readonly>
                                    <button @click="pop.showCManager" class="btn-default float-left h-30px">선택</button>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 상담내용</th>
                                <td>
                                    <textarea v-model.trim="mod.info.contents" class="w-100per h-100px"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 수업 지속성</th>
                                <td>
                                    <label v-for="(irow, i) in mod.cp_list" :key="i"><input type="radio" v-model="mod.info.class" :value="irow.code"><span class="ml-5 mr-20"><img :src="require('@/assets/img/db_relation_0' + (i+1) + '.png')" alt="ico" class="ml-5"> {{ irow.name }}</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="txt-red">*</span> 관계 지속성</th>
                                <td>
                                    <label v-for="(irow, i) in mod.rp_list" :key="i"><input type="radio" v-model="mod.info.relation" :value="irow.code"><span class="ml-5 mr-20"><img :src="require('@/assets/img/db_durability_0' + (i+1) + '.png')" alt="ico" class="ml-5"> {{ irow.name }}</span></label>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <h3 class="block-title mt-40 mb-20">외근수당</h3>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>목적</th>
                                <td>
                                    <label v-for="(irow, i) in mod.rt_list" :key="i"><input type="radio" v-model="mod.info.rtype" :value="irow.code" onclick="return false"><span class="ml-5 mr-20"> {{ irow.name}}</span></label>
                                    <p class="color-1">* 주말근무는 전자결재 > 추가 수당 신청서 작성 후 기안해 주세요.</p>
                                </td>
                            </tr>
                            <tr>
                                <th>클래스</th>
                                <td>
                                    <span class="float-left mr-10">{{ mod.info.classname }}</span>
                                    <button @click="pop.showClassList" class="btn-default float-left h-30px">클래스 선택</button>
                                </td>
                            </tr>
                            <tr>
                                <th>수당</th>
                                <td v-if="mod.info.rtype=='기타'"><input type="text" class="float-left mr-10" v-model="mod.info.price"></td>
                                <td v-if="mod.info.rtype!='기타'">{{ mod.info.price }} 원</td>

                            </tr>
                        </tbody>
                    </table>

                    <button @click="mod.doCancel" class="btn-default float-left mt-30">취소</button>
                    <button @click="mod.doSubmit" class="btn-default float-right mt-30">수정</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CompanyManagerPopup from '@/components/popup/customerManagement/CompanyManagerPopup.vue'
import SelectClassPopup from '@/components/popup/customerManagement/SelectClassPopup.vue'


export default {
    layout:"customerManagement",
    components: {
        CompanyManagerPopup, 
        SelectClassPopup
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast = useToast()

        const pop = reactive({
            // Popup 노출 여부
            company : 0,
            manager : "",

            isShowCManager : false,
            isShowClass    : false,

            showCManager : () => {
                pop.company     = mod.info.idx_company;
                pop.isShowCManager = true;
                console.log(pop.company);
            },
            hideCManager : () => {
                pop.company     = 0;
                pop.isShowCManager = false;
                console.log(pop.manager, mod.manager);
            },

            showClassList : () => {
                pop.company     = mod.info.idx_company;
                pop.isShowClass = true;
            },
            hideClassList : () => {
                pop.company     = 0;
                pop.isShowClass = false;
            }
        });

        const mod = reactive({
            cp_list : [],
            rp_list : [],
            rt_list : [],

            idx : 0,
            info : {
                idx_schedule    : 0,
                idx_bizcard     : 0,
                company_manager : "",
                contents        : "",
                class           : "",
                relation        : "",

                idx_class : 0,
                classname : "클래스를 선택하세요.",
                price     : 0,
            },

            doCancel : () => {
                router.go(-1);
            },

            doSubmit : () => {
                let params = {
                    idx      : mod.idx,
                    schedule : mod.info.idx_schedule,
                    bizcard  : mod.info.idx_bizcard,
                    contents : mod.info.contents,
                    class    : mod.info.class,
                    relation : mod.info.relation,

                    idx_class : mod.info.out_prc_idx_class,
                    classname : mod.info.classname,
                    price     : mod.info.price.replace(/[^0-9]/gi, '')
                };

                console.log(params);

                if( !params.bizcard || !params.contents || !params.class || !params.relation ){
                    toast.error("외근 수당 제외한 모든 항목을 입력해주세요.");
                }

                axios.post("/rest/customermgr/modOutsideWork", params).then((res) => {
                    if( res.data.err == 0 ){
                        router.push({
                            name : "customerManagement-outsideWorkView-idx",
                            params : {
                                idx : res.data.idx
                            }
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearchRtype : () => {
                axios.get("/rest/customermgr/getRtypeList", { params:{} }).then((res) => {
                    if( res.data.err == 0 ){
                        mod.rt_list = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            doSearchCP : () => {
                axios.get("/rest/customermgr/getCPtypeList", { params:{} }).then((res) => {
                    if( res.data.err == 0 ){
                        mod.cp_list = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            doSearchRP : () => {
                axios.get("/rest/customermgr/getRPtypeList", { params:{} }).then((res) => {
                    if( res.data.err == 0 ){
                        mod.rp_list = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            doSearch : () => {
                let params = {
                    idx : mod.idx
                };

                axios.get("/rest/customermgr/getOutsideWorkInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        mod.info = res.data;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            }
        });

        onMounted(() => {
            // Mounted
            mod.idx = route.params.idx?route.params.idx:0;

            if ( !mod.idx ) {
                router.back(-1);
            }

            mod.doSearch();
            mod.doSearchRtype();
            mod.doSearchCP();
            mod.doSearchRP();
        });

        return {pop, mod};
    }
}
</script>

<style lang="scss" scoped>
</style>